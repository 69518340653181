

    function gaTrack(path, title) {
        ga('set', {page: path, title: title});
        ga('send', 'pageview');
    }

    $.fn.serializeObject = function () {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function () {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };

    /* jQuery.values: get or set all of the name/value pairs from child input controls
     * @argument data {array} If included, will populate all child controls.
     * @returns element if data was provided, or array of values if not
     */

    $.fn.valuesFromSerialized = function (data) {
        var els = $(this).find(':input').get();

        $.each(els, function () {
            // if (this.name && data[this.name]) {
            if (this.type == 'checkbox' || this.type == 'radio') {
                var val = $(this).val();

                var found = false;
                if ($.isArray(data[this.name])) {
                    for (var i = 0; i < data[this.name].length; i++) {
                        if (data[this.name][i] == val) {
                            found = true;
                            // break;
                        }
                    }
                }
                else {
                    if (data[this.name] == val)
                        found = true;
                }
                $(this).prop("checked", found);
                // if(!found)
                //     $(this).removeAttr('checked');
            } else {
                $(this).val(data[this.name]);
                $(this).attr('value', data[this.name]);
            }
            // }
        });
        return $(this);
    };

    function searchToObject(search) {
        var pairs = search.substring(1).split("&"),
            obj = {},
            pair,
            i;

        for (i in pairs) {
            if (pairs[i] === "") continue;

            pair = pairs[i].split("=");
            obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
        }

        return obj;
    }

    function current_url() {
        return window.location.protocol + '//' + window.location.hostname + window.location.pathname;
    }


    /*
    array ksort  ( array $array)
    Trie le tableau array  suivant les clés, en maintenant la correspondance entre les clés et les valeurs.
    Cette fonction est pratique pour les tableaux associatifs.
*/
    function ksort(w)
    {
        var sArr = [];
        var tArr = [];
        var n = 0;
        for (i in w)
        {
            tArr[n++] = i;
        }
        //tri du plus petit au plus grand
        tArr = tArr.sort();
        n = tArr.length;
        for (var i=0; i<n; i++) {
            sArr[tArr[i]] = w[tArr[i]];
        }
        return sArr;
    }
    /*
        array krsort  ( array $array)
        krsort() trie le tableau array  en ordre inverse et suivant les clés, en maintenant la correspondance entre les clés et les valeurs.
        Cette fonction est pratique pour les tableaux associatifs.
    */
    function krsort(w)
    {
        var sArr = [];
        var tArr = [];
        var n = 0;
        for (i in w)
        {
            tArr[n++] = i;
        }
        //tri du plus grand au plus petit
        tArr = tArr.sort(function(a,b){return (b - a);});
        n = tArr.length;
        for (var i=0; i<n; i++)
        {
            sArr[tArr[i]] = w[tArr[i]];
        }
        return sArr;
    }

    function reverseArr(input) {
        var ret = new Array;
        for(var i = input.length-1; i >= 0; i--) {
            ret.push(input[i]);
        }
        return ret;
    }

    function redirect (url) {
        var ua        = navigator.userAgent.toLowerCase(),
            isIE      = ua.indexOf('msie') !== -1,
            version   = parseInt(ua.substr(4, 2), 10);

        // Internet Explorer 8 and lower
        if (isIE && version < 9) {
            var link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
        }

        // All other browsers can use the standard window.location.href (they don't lose HTTP_REFERER like Internet Explorer 8 & lower does)
        else {
            window.location.href = url;
        }
    }


//include imports/wNumb.js
//include imports/nouislider.js
//include imports/jquery.diffhtml.min.js


var UrlParser = {
    regx: /^(((([^:\/#\?]+:)?(?:(\/\/)((?:(([^:@\/#\?]+)(?:\:([^:@\/#\?]+))?)@)?(([^:\/#\?\]\[]+|\[[^\/\]@#?]+\])(?:\:([0-9]+))?))?)?)?((\/?(?:[^\/\?#]+\/+)*)([^\?#]*)))?(\?[^#]+)?)(#.*)?/
    ,
    get: function (url) {
        var matches = this.regx.exec(url);
        // map results
        return {
            href: matches[0],
            withoutHash: matches[1],
            url: matches[2],
            origin: matches[3],
            protocol: matches[4],
            protocolseparator: matches[5],
            credhost: matches[6],
            cred: matches[7],
            user: matches[8],
            pass: matches[9],
            host: matches[10],
            hostname: matches[11],
            port: matches[12],
            pathname: matches[13],
            segment1: matches[14],
            segment2: matches[15],
            search: matches[16],
            hash: matches[17]
        };
    }
};

$(document).ready(function () {
    /*=========================================\
        $SIDEBAR
    \=========================================*/

    if (window.matchMedia("only screen and (min-width: 1141px)").matches) {
        $(".sidebar-inner").stickOnScroll({
            footerElement: $("footer"),
            topOffset: $(".nav").outerHeight(),
            bottomOffset: 0,
            setParentOnStick: true,
            setWidthOnStick: true
        });
    }

    /*==========================================
     =             $FILTERS                    =
     ==========================================*/

    $('.toggle-plus').click(function (event) {
        event.preventDefault();
        $('#' + $(this).data('id')).toggleClass('filter-visible');
        $(this).css({'display': 'none'});
        $(this).prev('.toggle-minus').css({'display': 'block'});
        return false;
    });

    $('.toggle-minus').click(function (event) {
        event.preventDefault();
        $('#' + $(this).data('id')).toggleClass('filter-visible');
        $(this).next('.toggle-plus').css({'display': 'block'});
        $(this).css({'display': 'none'});
        return false;
    });

    $('#filter-toggle').on('click', function (event) {
        event.preventDefault();
        $('.sidebar').toggleClass('show');
        $('#overlay').toggleClass('is-visible');
    });

    $('#overlay').on('click', function () {
        if ($('.sidebar').hasClass('show')) {
            if ($('#overlay').hasClass('is-visible')) {
                $('#overlay').removeClass('is-visible');
            }
            $('.sidebar').removeClass('show');
        }
        // $('#overlay').toggleClass('is-visible');
    });

    /*=========================================\
     $RANGE
     \=========================================*/

    // $('.range').each(function (index) {
    //
    //     var range_element = $(this);
    //
    //     //-------------- Définition des variables
    //
    //     var id            = "range" + index,
    //         min           = range_element.data("rangeMin"),
    //         max           = range_element.data("rangeMax"),
    //         step          = range_element.data("rangeStep"),
    //         start         = range_element.data("rangeStart"),
    //         end           = range_element.data("rangeEnd"),
    //         connectMin    = range_element.data("connectMin"),
    //         connectMax    = range_element.data("connectMax"),
    //         Format        = wNumb({decimals: 0, thousand: ' '}),
    //         TooltipFormat = [wNumb({decimals: 0, thousand: ' ', suffix: ' €'}), wNumb({decimals: 0, thousand: ' ', suffix: ' €'})];
    //
    //     var options = {
    //         animate: true,
    //         animationDuration: 300,
    //         behaviour: 'drag-tap',
    //         tooltips: TooltipFormat,
    //         step: step,
    //         connect: true,
    //         margin: step,
    //         range: {
    //             'min': [min],
    //             'max': [max]
    //         },
    //         format: Format
    //     };
    //
    //     if (start == undefined && end != undefined) {
    //         options.start = [end];
    //     } else if (start != undefined && end == undefined) {
    //         options.start = [start];
    //     } else {
    //         options.start = [start, end];
    //     }
    //
    //     //-------------- Instanciation
    //
    //     range_element.attr("id", id);
    //     var range = document.getElementById(id);
    //     noUiSlider.create(range, options);
    //
    //     //-------------- Accessibilité
    //
    //     // range_element.on('focus', function () {
    //     // 	$(this).siblings('.focus-reveal').addClass('active');
    //     // 	range.remove().noUiSlider.destroy();
    //     // });
    //
    //     //-------------- Connect avec des inputs
    //
    //     range.noUiSlider.on('update', function (values, handle) {
    //         if (connectMax != undefined && handle) {
    //             var connect  = connectMax;
    //             var vallimit = max;
    //         } else {
    //             var connect  = connectMin;
    //             var vallimit = min;
    //         }
    //
    //         $("[name='" + connect + "']").val(Math.round(values[handle]));
    //
    //         $("." + connect + " .value").html(Math.round(values[handle]));
    //         // Si les handle sont aux positions par default, on applique pas la classe filled
    //         // On submit, seul les non null et .filled sont envoyé
    //         if (Math.round(values[handle]) != vallimit) {
    //             $("[name='" + connect + "']").not('.filled').addClass('filled');
    //         } else {
    //             $("[name='" + connect + "']").removeClass('filled');
    //         }
    //     });
    //
    //     range.noUiSlider.on('change', function () {
    //         range_element.closest('form').trigger('change');
    //     });
    //
    //     // If event reset form
    //     // range_element.closest('form').find("[type='reset']").on("click", function (event) {
    //     //     // stops the form from resetting after this function
    //     //     event.preventDefault();
    //     //     // resets the form before continuing the function
    //     //     $(this).closest('form').get(0).reset();
    //     //     // set initials values
    //     //     if (connectMin != undefined) {
    //     //         slider.noUiSlider.set([min, null]);
    //     //     }
    //     //     if (connectMax != undefined) {
    //     //         slider.noUiSlider.set([null, max]);
    //     //     }
    //     // });
    // });

    /*=========================================\
        $LISTEPRODUITS
    \=========================================*/

    var getProductAjax;

    function getProducts(data, url) {

        if (typeof getProductAjax !== 'undefined')
            getProductAjax.abort();

        // $('#form_filters').css('pointer-events', 'none');
        var parser = UrlParser.get(url);
        var newurl = parser.protocol + parser.protocolseparator + parser.hostname + parser.pathname;

        // console.log(window.location.pathname );

        // getProductAjax = $.get(newurl, data, function (result) {
        //     // $("#store_content").html(result.html);
        //     $("#store_content").diffhtml(result.html);
        //     // $("#active_filters").html(result.filters);
        //     $("#active_filters").diffhtml(result.filters);
        //     // $('#form_filters').css('pointer-events', 'auto');
        // });

        getProductAjax = $.ajax({
            dataType: "json",
            url: newurl,
            type: "GET",
            data: data,
            cache: false,
            beforeSend: function () {
	            var target = $('#store_content');
	            target.html('<div class="text-center loader"><svg width="120" height="30" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#222221"><circle cx="15" cy="15" r="15"><animate attributeName="r" from="15" to="15"begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"/><animate attributeName="fill-opacity" from="1" to="1"begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite"/></circle><circle cx="60" cy="15" r="9" fill-opacity="0.3"><animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9" calcMode="linear" repeatCount="indefinite"/><animate attributeName="fill-opacity" from="0.5" to="0.5" begin="0s" dur="0.8s" values=".5;1;.5" calcMode="linear" repeatCount="indefinite"/></circle><circle cx="105" cy="15" r="15"><animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"/><animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite" /></circle></svg><div class="text-upper medium text-bold m0">Chargement</div></div>');
                // $('#form_filters').addClass('processing');
            },
            success: function (data) {
                // $('#form_filters').removeClass('processing');
                var target = $('#store_content');
                target.html(data.html);
                $("#active_filters").html(data.filters);
	            qwinit();
	            ratingProduct();
                $('html, body').animate({scrollTop: target.offset().top - 200}, 200, 'easeInSine');
            },
	        error: function() {
		        var target = $('#store_content');
		        target.html('<div class="row-promo small"><div class="alert alert-default"><div><i class="fa fa-shopping-basket fa-3x"></i> <span class="text-upper text-font-alt lead text-bold has-color-theme">Pas de produits</span></div><div><p class="alert alert-info">Aucun produit trouvé !</p></div></div></div><hr>');
	        }
        });
    }

// console.log($('#form_filters').length);
    if ($('#form_filters').length) {
	    document.getElementById('form_filters').addEventListener('change', function (e) {
		    e.preventDefault();
		    var data = serializeObject(this),
			    data_serialized = serialize(this),
			    url;
		
		    if (data_serialized) {
			    url = window.location.protocol + '//' + window.location.hostname + window.location.pathname + '?' + data_serialized;
		    } else {
			    url = window.location.protocol + '//' + window.location.hostname + window.location.pathname;
		    }
		
		    $('#form_filters').find('.range').each(function (index) {
			    var range_element = $(this);
			    var slider        = document.getElementById("range" + index).noUiSlider;
			    var input_min     = range_element.closest('form').find('input[name="' + range_element.data("connectMin") + '"]');
			    var input_max     = range_element.closest('form').find('input[name="' + range_element.data("connectMax") + '"]');
			
			    // console.log(slider.get(), [input_min.val(), input_max.val()], slider.get()[0]);
			    // if(slider.get()[0] != input_min.val() && slider.get()[1] != input_max.val())
			    slider.set([input_min.val(), input_max.val()]);
		    });
		    
		    getProducts(data, url);
		    history.pushState(data, document.title, url);
		    if (typeof ga !== 'undefined') {
			    gaTrack(window.location.pathname + window.location.search + window.location.hash, document.title);
		    }
	    }, false);

        $('#form_filters').on('change submit', function (event) {
            event.preventDefault();
	        qwinit();
            ratingProduct();
            var target = $('#store_content');
            $('html, body').animate({scrollTop: target.offset().top - 200}, 200, 'easeInSine');
        });

        $('#store_content').on('click', '.paginate a', function (event) {
            event.preventDefault();
	        qwinit();
            ratingProduct();
            var target = $('#store_content');
            $('html, body').animate({scrollTop: target.offset().top - 200}, 200, 'easeInSine');
        });

        /*=========================================\
            $TAGS
        \=========================================*/

        $('.tags').on('click', 'a.tag', function (e) {
            e.preventDefault();
            var target_remove = $(this).data('uncheck');
            target_remove     = $('#' + target_remove);

            if (target_remove.is(':radio')) {
                target_remove.prop("checked", false);
            } else if (target_remove.is(':checkbox')) {
                target_remove.prop("checked", false);
            } else if (target_remove.is(':select')) {
                target_remove.val(0);
            }

            target_remove.parents('form').trigger('change');

            $(this).remove();
        });

        // $('.sort').on('click', 'li', function () {
        //     var $value = $(this).find('input[name=order]').val();
        //     $('#form_filters').find('select[name=order] option').prop('selected', false);
        //     $('#form_filters').find('select[name=order] option[value=' + $value + ']').prop('selected', true);
        //     $('#form_filters').trigger('change');
        // });
        //
        // $('#order').change(function () {
        //     var $value = $(this).find('option:selected').val();
        //     $('.sort').find('input[name=order]').prop('checked', false);
        //     $('.sort').find('input[value=' + $value + ']').prop('checked', true);
        // });
        //
        //
        // $('.sort-nbre').on('change', '#product_per_page_top', function () {
        //     var $value = $(this).val();
        //     $('#form_filters').find('select[name=product_per_page] option').prop('selected', false);
        //     $('#form_filters').find('select[name=product_per_page] option[value=' + $value + ']').prop('selected', true);
        //     $('#form_filters').trigger('change');
        // });
        //
        //
        // $('#product_per_page').change(function () {
        //     var $value = $(this).find('option:selected').val();
        //     $('.sort-nbre').find('select[name=product_per_page_top] option').prop('selected', false);
        //     $('.sort-nbre').find('select[name=product_per_page_top] option[value=' + $value + ']').prop('selected', true);
        // });
    }

    // $('#store_content').on('mouseenter', '.card-listen' , function (e) {
    //
    //     var product_id = $(this).data('card_id');
    //     var listener = $(this);
    //
    //     $.ajax({
    //         dataType: "json",
    //         url: 'getcardinfo/'+product_id,
    //         type: "GET",
    //         cache:false,
    //         beforeSend: function(){
    //             listener.removeClass('card-listen');
    //         },
    //         success: function (data) {
    //             var target =  listener.children('.card_loading');
    //             target.html(data.html);
    //         }
    //     });
    // } )


});